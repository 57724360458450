// Begin: Button
.btn {
    box-shadow: none;
    outline: none;
    @include button-size($btn-padding-y,$btn-padding-x,$btn-font-size,$line-height-base,$font-regular,$box-shadow-0);
   &:focus{
    box-shadow: 0px 4px 6px -1px rgba(26, 32, 44, 0.1), 0px 2px 4px -1px rgba(26, 32, 44, 0.06);
       &:active{
           box-shadow: none;
           box-shadow: 0px 4px 6px -1px rgba(26, 32, 44, 0.1), 0px 2px 4px -1px rgba(26, 32, 44, 0.06);
           outline: none;
       }
   }
    @include border-radius(4px);
    &.btn-sm{
        @include button-size($btn-padding-y-sm,$btn-padding-x-sm,$btn-font-size-sm,$btn-line-height-sm,$font-semibold,$box-shadow-0);
    }
    &.btn-lg{
        @include button-size($btn-padding-y-lg,$btn-padding-x-lg,$btn-font-size-lg,$line-height-base,$font-semibold,$box-shadow-0);
    }
    &:focus {
        text-decoration: none;
        box-shadow: 0px 4px 6px -1px rgba(26, 32, 44, 0.1), 0px 2px 4px -1px rgba(26, 32, 44, 0.06);
	}
	&:hover {
        text-decoration: none;
        box-shadow: 0px 4px 6px -1px rgba(26, 32, 44, 0.1), 0px 2px 4px -1px rgba(26, 32, 44, 0.06);
	}
    &.btn-primary{
        background-color: $btn-primary;
    }
    &.btn-success{
        background-color: $success;
        &:hover, &:focus, &:active{
            background-color: #1d5a3c;
            border-color: #1d5a3c;
            color: $white;
        }
    }
    &.btn-purple{
        background-color: $purple;
        color: $white;
    }
    &.btn-outline-primary{
        border-color: $dark;
        color: $light-black;
        &:hover, &:focus, &:active{
            background-color: $btn-primary;
            border-color: $btn-primary;
            color: $white;
        }
    }
    &.btn-radius{
        border-radius: 2.5rem!important;
    }
/* btns */
&.black-btn{
    color: $white;
    background: $light-black;
    border-radius: 4px;
    padding: 5px 12px;
    height: 34px;
    font-weight: 400;
    font-size: .875rem;
    line-height: .875rem;
    &:hover{
        color: $white;
        background: #202530;
        border-radius: 4px;
    }
    &:hover, &:focus{
        box-shadow: none;
    }
}
&.gray-line-btn{
    color: $dark;
    background: transparent;
    border: 1px solid #A0AEC0;
    &:hover, &:focus, &:active{
        background-color: $btn-primary;
        border-color: $btn-primary;
        color: $white;
    }
}
&.fixWidth-btn{
    max-width: 7.5rem;
        width: 100%;
}
/* end btns */
}
.disabled-btn{
    opacity: 0.3!important;
    cursor: not-allowed;
}
.btn-outline-light {
    color: rgba($black, 0.6);
    border-color: rgba($black, 0.6);
    &:hover, &:focus, &:not(:disabled):not(.disabled):active {
        background-color: $black;
        color: $white;
    }
}