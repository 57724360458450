.blacklayer-sidebar{
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 8;
    position: fixed;
    &.show{
        display: block;
    }
    &.hide{
        display: none;
    }
}
.dashboard-layout-wrap{
    .sidebar-block{
    min-height: 100%;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    padding-top: 5rem;
    width: 13.75rem;
    background: $primary;
    transition: all 0.2s;
    z-index: 9;
 
    @include mx-991{
        transform: translate(-100%, 0px);

        &.sidebar-show{
            transform: translate(0%, 0px); 
        }
    }
    
    .nav{
        overflow: auto;
        flex-flow: column;
        padding: 4.5rem 1rem 1rem 1rem;
        @include mx-991{
            padding: 2rem 1rem 1rem 1rem;
        }
        .nav-link{
            color: $white;
            padding: .625rem .9375rem .625rem .5rem;
            font-size: .875rem;
            line-height: 1.5rem;
            display: flex;
            align-items: center;
            [class*="icon-"]{
                font-size: 1.25rem;
                padding-right:1.125rem ;
            }
            &.active{
                font-weight: 700;
                background: rgba(255, 255, 255, 0.2);
                border-radius: .25rem;
                border-right: .25rem solid $white;
            }
            &:hover{
                font-weight: 700;
            }

        }
    }
  
    }
    .dashboard-content{
        margin-left: 220px;
        min-height: calc(100vh - 80px);
        overflow: auto;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        @include mx-991{
            margin-left: 0px;
            min-height: calc(100vh - 80px);
        }
    }
    .dashboard-inner-content{
        max-width: 63.375rem;
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
        padding-bottom: 3.75rem;
        
        margin: 0 auto 5rem auto;
        width: 100%;
        flex: 1 1 100%;
    }
   
}