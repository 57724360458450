/* form */

.form-control,
.form-control:focus,
input[type]:not(.browser-default):focus:not([type="radio"]):not([type="checkbox"]),
input[type]:not(.browser-default):not([type="radio"]):not([type="checkbox"]),
input[type]:not(.browser-default):focus:not([readonly]):not([type="radio"]):not([type="checkbox"]) {
    height: 44px;
    background: $white;
    border: 1px solid #CBD5E0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    outline: none!important;
    box-shadow: none!important;
    color: $light-black;
}

.form-control::-webkit-input-placeholder {
    color: $dark!important;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
}

.form-control::-moz-placeholder {
    color: $dark!important;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
}

.form-control:-ms-input-placeholder {
    color: $dark!important;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
}

.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: $dark!important;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
}

.input-ic-wrap {
    position: relative;
    &.date-empty [class*="icon-"] {
        color: $dark;
    }
    &.date-filled [class*="icon-"] {
        color: $light-black;
    }
    input.form-control {
        padding-right: 27px!important;
        &:placeholder-shown~[class*="icon-"] {
            color: $dark;
        }
        &:not(:placeholder-shown)~[class*="icon-"] {
            color: $light-black;
        }
    }
    [class*="icon-"] {
        position: absolute;
        right: 10px;
        top: 14px;
        font-size: 18px;
    }
}

.form-group {
    margin-bottom: 1.5rem;
}

.message-block {
    font-size: 12px;
    color: #ff9292;
}

// react select
.css-b62m3t-container {
    cursor: pointer;
    .custom-select__control {
        .custom-select__indicator-separator {
            display: none;
        }
        &.custom-select__control--is-focused {
            border-color: $light-black!important;
            box-shadow: 0 0 0 2px $secondary;
        }
        &.custom-select__control--menu-is-open {
            .custom-select__indicator {
                transform: rotate(180deg);
            }
        }
        .custom-select__indicator {
            color: $light-black!important;
            svg {
                width: 14px;
            }
        }
        input[type]:not(.browser-default) {
            &.custom-select__input {
                height: 30px!important;
            }
        }
        .custom-select__placeholder {
            color: $dark;
            white-space: nowrap;
        }
    }
    .custom-select__menu {
        margin: 8px 0px;
    }
    .custom-select__menu-list {
        padding: 8px;
    }
    .custom-select__option {
        padding: 8px;
        background: #F7FAFC;
        &:not(:last-child) {
            margin-bottom: 4px;
        }
        &.custom-select__option--is-selected {
            background: $primary;
        }
    }
}

// end react select
// google select
.css-2b097c-container{
    .css-yk16xz-control{
        .css-1okebmr-indicatorSeparator{
            display: none;
        }
    }
    .css-1g6gooi{
        input{
            height: 30px !important;
        }
    }
    .css-1pahdxg-control{
        border-color: $light-black!important;
            box-shadow: 0 0 0 2px $secondary;
            .css-1okebmr-indicatorSeparator{
                display: none;
            }
    }
    .css-tlfecz-indicatorContainer, 
    .css-1gtu0rj-indicatorContainer {
        color: $light-black!important;
        svg {
            width: .875rem;
        }
    }
    .css-1gtu0rj-indicatorContainer{
        transform: rotate(180deg);
    }
    .css-11unzgr{
        padding: .5rem;
        [class*="-option"]{
            cursor: pointer;
            padding: .5rem;
            background: #F7FAFC;
            &:not(:last-child) {
                margin-bottom: 4px;
            }
            &.css-9gakcf-option{
                background: $primary;
                color: $white;
            }
        }
    }
}
// end google select

// checkbox
.form-check{
    padding-left: 0px;
    margin: 0px;
    .form-check-input{
        display: none;
        & + .form-check-label{
            padding-left: 26px;
            position: relative;
            user-select: none;
            color: #1A202C;
            &::before{
                content: "";
                width: 16px;
                height: 16px;
                border: 1px solid #1A202C;
                border-radius: 2px;
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translate(0px, -50%);
            }
        }
    &:checked[type=checkbox]{
        & ~ .form-check-label{
            &::before{
                content: "\e9b3";
                background: $btn-primary;
                font-family: 'icomoon' !important;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-feature-settings: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                border: 1px solid $btn-primary;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    }
}
/* end form */