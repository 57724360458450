
.dashboard-header{
    position: fixed;
    top: 0;
    left: 0px;
    width: 100%;
    background: $white;
    transition: all 0.2s;
    &.header-shadow{
        box-shadow: 0px 4px 6px -1px rgba(26, 32, 44, 0.1), 0px 2px 4px -1px rgba(26, 32, 44, 0.06);
    }
    z-index: 10;
    .navbar{
        @include mx-991{
            background: $primary;
        }
    }
    .navbar-brand{
        background: #1419C2;
        padding: 1.8125rem;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 13.75rem;
        @include mx-991{
            flex: 1 1 7.5rem;
            padding: 1.4375rem .625rem;
            background: $primary;
        }
        @include mx-414{
            flex: 1 1 4.0625rem;
            padding: 1.4375rem .625rem;
            background: $primary;
        }
        img{
            max-width: 124px;
            width: 100%;
        }
    }
    .container-fluid{
        flex: 1 1 calc(100% - 13.75rem);
        .inner-container{  
          max-width: 61.5rem;
          margin: 0 auto;
    }
    }
    .form-group{
        max-width: 280px;
        width: 100%;
    }
.profile-img-wrap{
    display: flex;
    align-items: center;
    .notification-link{
        [class*="icon-"]{
            margin-right: 1.75rem;
            font-size: 1.25rem;
            color: $primary;
        }
    }  
    .profile-dropdown-wrap{
        display: flex;
        align-items: center;
        [class*="icon-"]{
            margin-left: .5rem;
            font-size: 1.25rem;
            color: #1A202C;
            &.icon-angle-down{ 
                 @include mx-991 {
                    color: $white;
                }
            }
            &.icon-signout{ 
                font-size: 1.0625rem;
            }
        }
        .notification-wrap {
            display: flex;
            align-items: center;
            [class*="icon-"]{
                color: $primary;
                font-size: 24px;
                @include mx-991{
                    color: $white;
                }
            }
        }
        .dropdown-menu{
            top: auto!important;
            bottom: -10px!important;
            transform: translateY(100%)!important;
            // position: absolute!important;
            min-height: 190px;
        }
    }
    .profile-img-block{
        display: flex;
        align-items: center;
        &::after{
            display: none;
        }
        &, &:focus, &:hover{
            padding: 0px;
            border: 0px;
            background: transparent!important;
            box-shadow: none!important;
        }
    }
}
    .profile-img{
        width: 2.75rem;
        height: 2.75rem;
        @include mx-575{
            width: 2rem;
            height: 2rem;
        }
     
    }
    .sidebar-toggler{
        color: $white;
        font-size: 34px;
        background: transparent;
        border: none;
        @include mx-575{
            font-size: 28px;
        }
        [class*="icon-"]{
            line-height: 1.5;
        }
    }
    .profile-img{
      
    }
}