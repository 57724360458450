// welcome

@keyframes bounceLeft { 50% { transform: translate(-5px, -50%); } }
.welcome-wrap {
    display: flex;
    padding: 6.875rem 1.25rem 1rem 1.25rem;
    align-items: center;
    max-width: 65.875rem;
    margin: 0 auto;
    @include mx-767 {
        padding: 8rem 1.25rem 1rem 1.25rem;
        flex-flow: column-reverse;
        img {
            max-width: 18.75rem;
            padding: 0px .9375rem;
        }
    }
    .welcome-text-block {
        flex: 1 1 18.5rem;
        padding-right: .625rem;
        .welcome-text-heading {
            margin-bottom: 1.5rem;
            font-weight: 900;
        }
        .welcome-btn-wrap {
            .btn {
                min-width: 7.5rem;
                &:not(:last-child) {
                    margin-right: .5rem;
                }
            }
            padding-bottom: 1rem;
        }
        .welcome-or {
            line-height: 1.5rem;
            padding-bottom: 1rem;
        }
        a {
            font-weight: 700;
        }
    }
    .welcome-img-block {
        flex: 1 1 calc(100% - 18.5rem);
    }
}

// end welcome

/* auth */

.nav-line-main {
    display: none;
}

.auth-main {
    .container {
        margin: 0px auto;
    }
    .auth-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 35.25rem;
        width: 100%;
        margin: 5rem auto 1.875rem auto;
        padding: 3.5rem .9375rem;
        background: $white;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15), 0px -4px 16px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        align-items: center;
        .form-group {
            margin-bottom: 1rem;
        }
    }
    .auth-heading {
       
        font-style: normal;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
        text-align: center;
    }
    .auth-subheading {
        color: #000;
        text-align: center;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        max-width: 23rem;
    }
    .back-login{
        margin-top: 1rem;
        display: inline-block;
        color: #000;
    }
    .password-btn {
        margin-top: 16px;
    }
    .googlesignin-box {
        align-items: center;
        max-width: 23rem;
        width: 100%;
        height: 2.125rem;
        display: flex;
        justify-content: center;
        border-radius: .25rem;
        margin: 2rem 0px;
        border: 1px solid $dark!important;
        color: $dark;
        text-align: center;
        box-shadow: none!important;
        background: transparent!important;
        border-radius: 4px!important;
        span {
            color: $dark;
        }
    }
    .or-text,
    .googlesignin-box {
     
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.5rem;
        margin-bottom: 1.875rem;
        color: $dark;
        text-align: center;
    }
    .form-wrap {
        max-width: 23rem;
        width: 100%;
    }
    .condition-text {
      
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.5rem;
        margin: 2rem 0px;
        color: $dark;
    }
    .filter-option {
        display: flex;
        align-items: center;
    }
    .filter-option-inner-inner {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
}

.condition-text {
 
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin: 2rem 0px;
    color: $dark;
}

.condition-text a {
    text-decoration: none;
    color: inherit;
    border-bottom: 1px solid $dark;
}

.createAcount-wrap #side-steps {
    display: none;
}


/* end auth */


/* step pages */

.step-main-wrap {
    width: 100%;
    .createAcount-wrap {
        padding: 0px .9375rem;
        min-height: calc(100vh - 140px);
    }
    .progress-baar {
        position: absolute;
        left: 0px;
        background: $gray-border;
        width: 100%;
        height: 1rem;
    }
    .progress-baar-value {
        background: $primary;
        height: 100%;
    }
    .step-wrap {
        max-width: 46.125rem;
        padding-top: 7rem;
        padding-bottom: 5rem;
        margin: 0 auto;
        @include mx-991{
            padding-top: 5rem; 
        }
        &.last-step-wrap{
            max-width: 47.5rem;
        }
    }
    .step-heading {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
    .step-heading:not(:last-child) {
        margin-bottom: 1.375rem;
    }
    .step-idea-box {
        margin: 2rem 0px;
        display: flex;
        align-items: center;
        background: #F7FAFC;
        border-radius: .3125rem;
        padding: 1.375rem 1rem;
        .step-idea-ic {
            flex: 1 1 4.8125rem;
            max-width: 4.8125rem;
            [class*="icon-"] {
                 width: 3.75rem;
                 height: 3.75rem;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 color: $light-black;
                 font-size: 1.5rem;
                 background: $white;
                 border-radius: 100%;
             }
        }
        .step-idea-text {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            flex: 1 1 calc(100% - 4.8125rem);
            max-width: calc(100% - 4.8125rem);
        }
    }
    .step-footer {
        background: $white;
        box-shadow: 0px -4px 4px -1px rgba(26, 32, 44, 0.04), 0px -2px 6px -1px rgba(26, 32, 44, 0.1);
        padding: 13px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px .9375rem;
    }
    .step-footer-cotainer .btn {
        max-width: 7.5rem;
        width: 100%;
        padding: .3125rem;
    }
    .step-footer-cotainer {
        max-width: 35.25rem;
        text-align: right;
        padding: .75rem 0rem;
        width: 100%;
    }
    .tag-wrap {
        margin-top: .875rem;
    }
    .stepform-heading {
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 2rem;
    }
  
    .fist-step-select {
        margin-bottom: 4rem;
    }
    .number-group {
        max-width: 29.125rem;
        width: 100%;
        display: flex;
    }
    .number-group .form-group {
        flex: 0 0 calc(100% - 10.75rem);
        max-width: calc(100% - 10.75rem);
    }
    .number-group .input-ic-wrap {
        margin-left: 1.5rem;
    }
    .step-form-wrap {
        max-width: 23rem;
        width: 100%;
    }
    .step-skip-btn {
        position: absolute;
        right: 148px;
        transform: translate(0px, 2rem);
        border-bottom: 1px solid $dark;
        line-height: 18px;
        color: $dark;
        @include mx-991{
            right: auto;
            left: 50%;
            transform: translate(-50%, 2rem);
            text-align: center;
            max-width: 80%;
        }
    }
}


/* end step pages */

.rsw_2Y {
    overflow: hidden;
}

.rsw_2f {
    display: none;
    &.rsw_3G {
        display: block;
    }
}

.react-datepicker__input-container {
    input {
        width: 100%;
    }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: $primary!important;
}

.dropzon-wrap {
    width: 100%;
    padding: 22px;
    display: flex;
    align-items: center;
    align-items: center;
    flex-flow: column;
    padding: 1.375rem;
    position: relative;
    margin-bottom: 14px;
    margin-top: 2rem;
    [class*="icon-"] {
        font-size: 1.25rem;
        color: $dark;
    }
    .dropzone-heading {
        margin-top: 10px;
        font-weight: 400;
        color: $dark;
    }
    .dropzone-text {
        margin-top: 8px;
        text-align: center;
        color: $black;
    }
    svg {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
    }
    path {
        fill: none;
        stroke: #CBD5E0;
        stroke-width: 2;
        stroke-dasharray: 5, 5;
    }
}

.preview-wrap {
    width: 185px;
    height: 120px;
    border: 1px solid #CBD5E0;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    .preview-ic {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        color: $white;
        top: .625rem;
        right: .625rem;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.31);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
    }
}

// last step
.last-step-wrap{
    .statusMain-block{
    max-width: 47.5rem;
    margin: 0 auto;
  .statusMain-tile{
    border: 1px solid $gray-border;
    border-radius: .625rem;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    position: relative;
    &:not(:last-child){
        margin-bottom: 1rem;
    }
    .statusMain-text-wrap{
        flex: 1 1 calc(100% - 5.25rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mx-991{
            flex-flow: column;
            justify-content: flex-start; 
            align-items: flex-start;
        }
    }
    .statusMain-status{
        padding-left: 15px;
        @include mx-991{
            padding-left: 0px;
            margin-top: 15px;
        }
        .icon-check{
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.25rem;
            border-radius: 100%;
            border: 1px solid #E5E5E5;
            background: #eaf7f4;
            color: $light-black;
    }       
    }
    &.current-verifyStep-tile{
        background: linear-gradient(0deg, rgba(44, 177, 145, 0.05), rgba(44, 177, 145, 0.05)), $white;
        border: 2px solid #2CB191;
                background: -webkit-linear-gradient(0deg, rgba(44, 177, 145, 0.05), rgba(44, 177, 145, 0.05)), $white;
        border: 2px solid #2CB191;
                background: -moz-linear-gradient(0deg, rgba(44, 177, 145, 0.05), rgba(44, 177, 145, 0.05)), $white;
        border: 2px solid #2CB191;
                background: -o-linear-gradient(0deg, rgba(44, 177, 145, 0.05), rgba(44, 177, 145, 0.05)), $white;
        border: 2px solid #2CB191;
                background: -ms-linear-gradient(0deg, rgba(44, 177, 145, 0.05), rgba(44, 177, 145, 0.05)), $white;
        border: 2px solid #2CB191;
        box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
    }
    &.pending-verifyStep-tile{
        background: linear-gradient(0deg, #F7FAFC, #F7FAFC), $white;
border: 1px solid $gray-border;
        border: 2px solid #2CB191;
                background: -webkit-linear-gradient(0deg, #F7FAFC, #F7FAFC), $white;
border: 1px solid $gray-border;
        border: 2px solid #2CB191;
                background: -moz-linear-gradient(0deg, #F7FAFC, #F7FAFC), $white;
border: 1px solid $gray-border;
        border: 2px solid #2CB191;
                background: -o-linear-gradient(0deg, #F7FAFC, #F7FAFC), $white;
border: 1px solid $gray-border;
        border: 2px solid #2CB191;
                background: -ms-linear-gradient(0deg, #F7FAFC, #F7FAFC), $white;
border: 1px solid $gray-border;
        border: 2px solid $gray-border;
    }
   
    .statusMain-heading{
        margin-bottom: 3px;
        font-weight: bold;
    }
    .statusMain-count{
        width: 3.75rem;
        height: 3.75rem;
        margin-right: 1.5rem;
        border-radius: 100%;
        border: 1px solid #E5E5E5;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mx-991{
            width: 2.5rem;
            height: 2.5rem;  
            margin-right: 1rem;
        }
    }
    .statusMain-status{
        .btn{
            white-space: nowrap;
        }
    }
    .current-indicator{
        position: absolute;
        right: -3.5rem;
        transform: translate(0%, -50%);
        font-size: 1.875rem;
        top: 50%;
        // transition: left 0.5s ease-in-out;
        animation: bounceLeft 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
        @include mx-991{
            display: none;
        }
    }
}

}
.connect-later-link{
    margin-top: 2rem;
    color: $dark;
    display: inline-block;
 }
}

// sign
.modal-dialog .modal-body{
    .signature-wrap{
        padding-top: 4rem;
        padding-bottom: 2rem;
        background: #F7FAFC;
        .signature-text{
            margin-bottom: 21px;
            text-align: center;
            &:last-child{
                margin-bottom: 32px;
            }
        }
        .signture-box{
            max-width: 564px;
            margin: 0px auto;
            background: $white;
            text-align: center;
            border: 1px solid #CBD5E0;    
            padding:32px;
            border-radius: 5px;
            position: relative;
            .canvas-width{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                
            }
            .signature-canvas{
                max-width: 100%;
                border-bottom: 1px solid #000;    
                margin: 0 auto;
                padding-bottom: 40px;
            }
        }
    }
}
  
// end sign 

// stripe payment
// .payment-header{

// }
// end stripe payment